import React from 'react';
import {Link} from 'react-router-dom';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button} from 'reactstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import moment from 'moment';
import 'moment-timezone';
import {getEquipment} from "../Equipment/EquipmentFunctions";
import getLocations from "../Locations/LocationFunctions";
import ReactSpeedometer from "react-d3-speedometer"

moment.tz.setDefault("America/Halifax");

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            equipment: [],
            vehicles: {},
            vehicles_sort: [],
            loading: true,
            locations: {locations: {}, locations_sort: []},
            pings: [],
            utilization: {}
        };
    }

    componentDidMount() {
        var self = this;
        getEquipment(0, function (equipment) {
            getLocations(function (locations) {
                self.setState({
                    locations: locations,
                    equipment: equipment
                });
                axios.get(API_ROOT + '/equipment/pings?id=' + moment().unix())
                    .then(function (response) {
                        self.setState({
                                pings: response.data,
                                loading: false,
                                last_update: moment()
                            },
                        );
                    })
                axios.get(API_ROOT + '/utilization')
                    .then(function (response) {
                        self.setState({
                                utilization: response.data,
                            },
                        );
                    })
            });
        });
    }


    render() {
        var selected_yards = [2357, 2342, 24, 150, 86, 95, 1, 94, 1765];
        var trucks_at_selected_yards = [];
        var num_oos = 0;
        var utilized_total = Object.values(this.state.utilization).reduce((acc, val) => acc + val, 0);
        var utilized_total_possible = Object.keys(this.state.utilization).length;
        var utilization = (utilized_total / utilized_total_possible);
        if (isNaN(utilization)) {
            utilization = 0;
        }
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Dashboard
                    </div>
                </div>
                <Row>
                    <div className="col-lg-6">
                        <div className={(this.state.loading ? "card card-default whirl traditional mb-2" : "card card-default mb-2")}>
                            <div>
                                <div className="card-header pb-0">
                                    <div className="card-title">Main Yards</div>
                                </div>
                                <div className="card-body pt-0">
                                    <table className={(this.state.locations.locations_sort.length > 0 ? "table table-sm table-hover table-pointer text-right" : "d-none")}
                                           style={{tableLayout: 'fixed'}}>
                                        <thead>
                                        <tr>
                                            <th className="text-left" width="33%">Name</th>
                                            <th className="text-left">Trucks</th>
                                            <th className="text-left">Trailers</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {selected_yards.map((location_id, key) => {
                                            if (typeof this.state.locations.locations[location_id] != "undefined") {
                                                var location = this.state.locations.locations[location_id];
                                                var i1 = 0;
                                                var i2 = 0;
                                                return (
                                                    <tr key={key}>
                                                        <td className="text-left">{location.name}</td>
                                                        <td className="text-left text-wrap" style={{wordWrap: 'break-word'}}>
                                                            {Object.entries(this.state.equipment).map(([equipment_id]) => {
                                                                if (typeof this.state.pings[equipment_id] != "undefined" && typeof this.state.pings[equipment_id][0] != "undefined" && this.state.pings[equipment_id][0].desc == location.name) {
                                                                    if (this.state.equipment[equipment_id].is_vehicle == 1 && this.state.equipment[equipment_id].status != 0) {
                                                                        trucks_at_selected_yards.push(equipment_id);
                                                                        i1++;
                                                                        return (
                                                                            <>
                                                                                <span className={(i1 == 1 ? "d-none" : "")}>, </span>
                                                                                <Link to={"../vehicles/" + equipment_id}
                                                                                      className={(this.state.equipment[equipment_id].status == 2 ? "text-danger" : "")}>{this.state.equipment[equipment_id].name}</Link>
                                                                            </>
                                                                        )
                                                                    }
                                                                }
                                                            })}
                                                        </td>
                                                        <td className="text-left text-wrap" style={{wordWrap: 'break-word'}}>
                                                            {Object.entries(this.state.equipment).map(([equipment_id]) => {
                                                                if (typeof this.state.pings[equipment_id] != "undefined" && typeof this.state.pings[equipment_id][0] != "undefined" && this.state.pings[equipment_id][0].desc == location.name) {
                                                                    if (this.state.equipment[equipment_id].is_vehicle != 1) {
                                                                        i2++;
                                                                        return (
                                                                            <>
                                                                                <span className={(i2 == 1 ? "d-none" : "")}>, </span>
                                                                                <Link to={"../equipment/" + equipment_id}
                                                                                      className={(this.state.equipment[equipment_id].status == 2 ? "text-danger" : "")}>{this.state.equipment[equipment_id].name}</Link>
                                                                            </>
                                                                        )
                                                                    }
                                                                }
                                                            })}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <div>
                                <div className="card-header pb-0">
                                    <div className="card-title">Out of Service Trucks
                                        <i className="small float-right"><i className="fa fa-suitcase text-muted"></i> = Not at a Main Yard</i>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    {Object.entries(this.state.equipment).map(([equipment_id, i]) => {
                                        if (this.state.equipment[equipment_id].is_vehicle == 1 && this.state.equipment[equipment_id].status == 2) {
                                            num_oos++;
                                            return (
                                                <>
                                                    <span className={(num_oos == 1 ? "d-none" : "")}>, </span>
                                                    <Link to={"../vehicles/" + equipment_id}>{this.state.equipment[equipment_id].name}</Link><i
                                                    className={(!trucks_at_selected_yards.includes(equipment_id) ? "fa fa-suitcase ml-1 text-muted" : "")}/>
                                                </>
                                            )
                                        }
                                    })}
                                    <div className={(num_oos == 0 && !this.state.loading ? "alert alert-success pt-1 pb-1 mb-0" : "d-none")}><i
                                        className="fas fa-check fa-lg mr-2"></i> No OOS trucks
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <div>
                                <div className="card-header pb-0">
                                    <div className="card-title">Truck Utilization Rate (1 Week)</div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="w-100 text-center" style={{height: "180px", width: "400px"}}>
                                        <ReactSpeedometer
                                            customSegmentStops={[0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1]}
                                            segmentColors={["firebrick", "firebrick", "firebrick", "firebrick", "firebrick", "gold", "limegreen", "limegreen", "gold", "firebrick"]}
                                            value={utilization}
                                            currentValueText={`${Math.round(utilization * 100)}%`}
                                            minValue={0}
                                            valueFormat=".0%"
                                            needleTransition="easeElastic"
                                            maxValue={1}
                                        />
                                    </div>
                                    <table className="table table-sm">
                                        <tbody>
                                        <tr>
                                            {Object.entries(this.state.utilization).map(([day, rate]) => {
                                                return (
                                                    <td className="text-center">
                                                        <b>{day}</b><br/>{Math.round(rate * 100)}%
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </ContentWrapper>
        );
    }
}

export default (Dashboard);
